
import { Component, Mixins } from 'vue-property-decorator';
import {
  Office,
  MapSettings,
  MapMarker,
  Profile,
} from 'client-website-ts-library/types';
import { API, Config } from 'client-website-ts-library/services';
import { Map } from 'client-website-ts-library/components';
import { View } from 'client-website-ts-library/plugins';
import Form from '@/components/Forms/Form.vue';

import { ProfileFilter } from 'client-website-ts-library/filters';

import ProfileCards from '@/components/ProfileCards.vue';

@Component({
  components: {
    Map,
    Form,
    ProfileCards,
  },
})
export default class ContactUs extends Mixins(View) {
  private office: Office | null = null;

  private mapSettings: MapSettings | null = null;

  private profiles: Profile[] | null = null;

  mounted() {
    API.Profiles.Search(new ProfileFilter()).then((profiles) => { this.profiles = profiles; });

    API.Offices.Get(Config.Website.Settings!.WebsiteId).then((office) => {
      this.office = office;

      this.mapSettings = new MapSettings({
        Markers: [
          new MapMarker({
            Address: `${this.office.AddressLine1} ${this.office.AddressLine2}`,
            Title: this.office.FranchiseName,
          }),
        ],
      });

      console.debug(this.mapSettings);
    });
  }
}
